import { z } from 'zod';

import { ZObjectId } from './objectid';
import { AllTargetNames, TargetNames, ZTargetAreasPatient } from './targets';
import { EClinicalReadingTypeList } from './dictionary/clinicalReadingTypeDictionary';
import { ZAddress } from './child/address';
import { ZPhoneNumber } from './child/phoneNumber';
import { ZDateString } from './date';

const ZCodeFilter = z.object({
  codes: z.array(z.enum(EClinicalReadingTypeList)),
  dateRange: z.enum(['trailing_12_months', 'this_financial_year']).optional(),
  valueRange: z.object({ min: z.number(), max: z.number() }).optional(),
});

const ZTargetAreaFilter = z.object({
  targetArea: ZTargetAreasPatient.keyof(),
});

const ZIncompleteTargetFilter = z.object({
  incompleteTargets: z.array(
    z.enum(AllTargetNames as [TargetNames, ...TargetNames[]]),
  ),
});

const ZOnboardingFilter = z.union([
  ZCodeFilter,
  ZTargetAreaFilter,
  ZIncompleteTargetFilter,
]);

const ZMobRemoveFilter = z.object({
  monthOfBirth: z.array(
    z.enum(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']),
  ),
});

const ZNottableOnboardingFilter = ZOnboardingFilter.or(
  z.object({ not: ZOnboardingFilter }),
);

const ZOneOrMinusOne = z.literal(1).or(z.literal(-1));
const ZSort = z.object({
  numericValue: ZOneOrMinusOne,
});
const ZCodeSort = z.union([
  ZCodeFilter,
  z.object({
    sort: ZSort,
  }),
]);

const ZPrioritisationRule = ZCodeSort.or(ZOnboardingFilter);

const ZRemoveFilter = z.union([
  ZNottableOnboardingFilter,
  ZMobRemoveFilter,
  z.object({
    and: z.array(z.union([ZNottableOnboardingFilter, ZMobRemoveFilter])),
  }),
]);

const ZPracticeOnboardingRules = z.object({
  // This field is just used in onboarding and signifies the target areas that we expect to have EHR reports on for the practice
  extractedTargetAreas: z.array(ZTargetAreasPatient.keyof()),
  filters: z
    .object({
      targetAreas: z.array(
        z.union([
          ZTargetAreasPatient.keyof(),
          z.object({
            and: z.array(ZTargetAreasPatient.keyof()),
          }),
        ]),
      ),
      remove: z.array(ZRemoveFilter).optional(),
    })
    .optional(),
  prioritisation: z.array(
    z.union([
      ZPrioritisationRule,
      z.object({
        and: z.array(ZPrioritisationRule),
      }),
    ]),
  ),
  onboarding_rules_description: z.string().optional(),
});

const ZPracticeEmail = z.object({
  type: z.string(),
  value: z.string(),
});
export type IPracticeEmail = z.infer<typeof ZPracticeEmail>;

const ZAccessType = z.enum([
  'service_account',
  'smartcard',
  'smartcard_prescribing',
]);
export type EAccessType = z.infer<typeof ZAccessType>;

const ZPracticeAccess = z.object({
  suverian: ZObjectId,
  access_type: ZAccessType,
});
export type IPracticeAccess = z.infer<typeof ZPracticeAccess>;

export const ZPracticeAllocationType = z.enum(['manual', 'automated']);
export type EPracticeAllocationType = z.infer<typeof ZPracticeAllocationType>;

const ZPracticeAllocation = z.object({
  suverian: ZObjectId,
  allocation_type: ZPracticeAllocationType,
});

export type IPracticeAllocation = z.infer<typeof ZPracticeAllocation>;

export const ZPractice = z.object({
  _id: ZObjectId,
  active: z.boolean(),
  test_practice: z.boolean().optional(),
  clinical_commissioning_group: ZObjectId,
  primary_care_network: ZObjectId,
  name: z.string().min(1),
  sms_sender_id: z.string().max(11).optional(),
  landing_page: z.string().optional(),
  address: ZAddress,
  phone_numbers: z.array(ZPhoneNumber),
  ehr_system: z.enum(['EMIS', 'SystmOne']),
  emis_org_code: z.string().optional(),
  access: z.union([z.array(ZPracticeAccess), z.null()]).optional(),
  emails: z.array(ZPracticeEmail),
  allocated_suverians: z
    .union([z.array(ZPracticeAllocation), z.null()])
    .default([]),
  odsCode: z.string(),
  practice_onboarding_rules: ZPracticeOnboardingRules.optional(),
  lastSynced: ZDateString.optional(),
  // eslint-disable-next-line unicorn/no-null
  contractEndDate: ZDateString.nullable().default(null),
});

export const ZCreatePractice = ZPractice.omit({
  _id: true,
})
  .required({
    odsCode: true,
  })
  .extend({
    address: ZAddress.optional(),
    phone_numbers: z
      .array(
        z.object({
          number: z.string().min(10),
          type: z.literal('other'),
        }),
      )
      .min(0),
    emails: z
      .array(
        z.object({
          type: z.literal('general'),
          value: z.string().email(),
        }),
      )
      .min(0),
    ehr_system: z.enum(['', 'EMIS', 'SystmOne']),
  });

export type ICreatePractice = z.infer<typeof ZCreatePractice>;

export const ZPatchPractice = ZCreatePractice.pick({
  odsCode: true,
  address: true,
  phone_numbers: true,
  emails: true,
  ehr_system: true,
  emis_org_code: true,
  sms_sender_id: true,
  practice_onboarding_rules: true,
})
  .strict()
  .partial();

export type IPatchPractice = z.infer<typeof ZPatchPractice>;

export type IPractice = z.infer<typeof ZPractice>;

export type EEHRSystem = IPractice['ehr_system'];

export type IPracticeOnboardingRemoveRules = z.infer<typeof ZRemoveFilter>;

export type IPracticeOnboardingRules = IPractice['practice_onboarding_rules'];

export type IOnboardingFilter = z.infer<typeof ZOnboardingFilter>;
export type INottableOnboardingFilter = z.infer<
  typeof ZNottableOnboardingFilter
>;
export type IPrioritisationRule = z.infer<typeof ZPrioritisationRule>;
