import { z } from 'zod';
import { ZDateString } from './date';
import { ZObjectId } from './objectid';
import { ZPatientId } from './patient';
import { ZSuverian } from './suverian';

const ZTaskCompletionType = z.enum(['completed', 'cancelled']);
export type ETaskCompletionType = z.infer<typeof ZTaskCompletionType>;

const ZTaskCompletion = z.object({
  datetime: ZDateString,
  note: z.string(),
  completion_type: ZTaskCompletionType,
  suverian: ZObjectId,
});
export type ITaskCompletion = z.infer<typeof ZTaskCompletion>;

const ZTaskAssignment = z.object({
  datetime: ZDateString,
  assigned_by: ZObjectId,
  assigned_to: ZObjectId,
});
export type ITaskAssignment = z.infer<typeof ZTaskAssignment>;

const ZTaskAssignmentPopulated = ZTaskAssignment.omit({
  assigned_by: true,
  assigned_to: true,
}).extend({
  assigned_by: ZSuverian,
  assigned_to: ZSuverian,
});
export type ITaskAssignmentPopulated = z.infer<typeof ZTaskAssignmentPopulated>;

const ZTaskCompletionPopulated = ZTaskCompletion.omit({
  suverian: true,
}).extend({
  suverian: ZSuverian,
});
export type ITaskCompletionPopulated = z.infer<typeof ZTaskCompletionPopulated>;

export const ZClinicalRiskLevel = z.enum([
  'controlled',
  'mild',
  'moderate',
  'severe',
  'no-data',
]);
export type IClinicalRiskLevel = z.infer<typeof ZClinicalRiskLevel>;

export const ZBloodPressureClinicalRiskCriteria = z.object({
  clinicalRiskLevel: ZClinicalRiskLevel,
  averageSystolic: z.number().nullable(),
  averageDiastolic: z.number().nullable(),
  currentAge: z.number().nullable(),
});

export type IBloodPressureClinicalRiskCriteria = z.infer<
  typeof ZBloodPressureClinicalRiskCriteria
>;

export const ZActClinicalRiskCriteria = z.object({
  // ACT doesn't have a corresponding mild level
  clinicalRiskLevel: ZClinicalRiskLevel.exclude(['mild']),
  actScore: z.number().nullable(),
  actHadSevereRiskAnswer: z.boolean().nullable(),
});

export type IActClinicalRiskCriteria = z.infer<typeof ZActClinicalRiskCriteria>;

export const ZClinicalRiskCriteria = z.object({
  clinicalRiskLevel: ZClinicalRiskLevel.exclude(['no-data']),
  // review period is optional if the task is created manually
  reviewPeriodStartDate: ZDateString.optional(),
  reviewPeriodEndDate: ZDateString.optional(),
  bloodPressure: ZBloodPressureClinicalRiskCriteria.optional(),
  act: ZActClinicalRiskCriteria.optional(),
});
export type IClinicalRiskCriteria = z.infer<typeof ZClinicalRiskCriteria>;

export const ZTaskDueDates = z.object({
  datetime: ZDateString,
  dueAtDatetime: ZDateString,
  daysAllowedToAction: z.number(),
  assignByDatetime: ZDateString,
  daysAllowedToAssign: z.number(),
  auditAtDatetime: ZDateString,
  daysAllowedBeforeAudit: z.number(),
});
export type ITaskDueDates = z.infer<typeof ZTaskDueDates>;

export const ZBaseTask = z.object({
  _id: ZObjectId,
  patient: ZPatientId,
  partner: ZObjectId.optional(),
  suverian: ZObjectId,
  task_type: z.string(),
  datetime: ZDateString,
  start: ZDateString,
  note: z.string(),
  completion: ZTaskCompletion.optional(),
  assignment: z.array(ZTaskAssignment),
  dueDates: z.array(ZTaskDueDates).optional(),
});

export type IBaseTask = z.infer<typeof ZBaseTask>;

export const ALL_OTHER_TASK_TYPES = [
  // Urgent review
  'urgent_clinical_review',
  'urgent_results_review',
  // Routine review
  'clinical_review',
  // Respond to patient
  'respond_to_voicemail',
  'respond_to_phone_call',
  'respond_to_message',
  'respond_to_email',
  // Opt out
  'opt_out_discussion',
  // Engagement
  're_engagement_call',
  'final_attempt_to_engage',
  'consider_handover',
  // Live Referrals
  'gp_referral',
  'registration',
  // Future Planning
  'check_results',
  'book_follow_up',
  // Admin
  'update_ehr',
  'action_from_audit',
  'other_task',
  'manually_offboard',
  // Skip task
  'target_review',
  // legacy - no longer created
  'attempt_to_engage',
  're_engagement_booking_window',
  'send_care_package',
  'request_care_package_return',
  'scheduled_re_engagement_sms',
] as const;

export const ZOtherTaskTypes = z.enum(ALL_OTHER_TASK_TYPES);

const ZOtherTask = ZBaseTask.omit({
  task_type: true,
}).extend({
  task_type: ZOtherTaskTypes,
});
export type IOtherTask = z.infer<typeof ZOtherTask>;

export const ALL_FORM_TYPES = ['lifestyle', 'asthma'] as const;
export const ZAllFormTypes = z.enum(ALL_FORM_TYPES);
export type FormType = z.infer<typeof ZAllFormTypes>;

const ZFormTask = ZBaseTask.omit({
  task_type: true,
}).extend({
  task_type: z.literal('submit_form'),
  formType: ZAllFormTypes,
});
export type IFormTask = z.infer<typeof ZFormTask>;

const ZSubmitTriageBPTask = ZBaseTask.omit({
  task_type: true,
}).extend({
  task_type: z.literal('submit_triage_bp'),
  numberOfSetsRequested: z.number().optional(),
});
export type ISubmitTriageBPTask = z.infer<typeof ZSubmitTriageBPTask>;

const ZSubmitBPTask = ZBaseTask.omit({
  task_type: true,
}).extend({
  task_type: z.literal('submit_bp'),
  numberOfSetsRequested: z.number(),
});
export type ISubmitBPTask = z.infer<typeof ZSubmitBPTask>;

export const ZAsyncReviewCriteria = z.object({
  reviewPeriodEndDate: ZDateString,
  reviewPeriodStartDate: ZDateString,
  hypertension: z
    .object({
      averageSystolic: z.number(),
      averageDiastolic: z.number(),
      currentAge: z.number(),
    })
    .optional(),
  hypertensionCaseFinding: z
    .object({
      averageSystolic: z.number(),
      averageDiastolic: z.number(),
      currentAge: z.number(),
    })
    .optional(),
  asthma: z
    .object({
      actScore: z.number(),
      mostRecentActDatetime: z.date(),
      mostRecentActHadSevereRiskAnswer: z.boolean(),
    })
    .optional(),
  diabetes: z
    .object({
      averageSystolic: z.number(),
      averageDiastolic: z.number(),
      currentAge: z.number(),
      completedTargets: z.array(
        z.enum(['QOF_DM020', 'QOF_DM021', 'QOF_DM022', 'QOF_DM023']),
      ),
      egfrDatetime: z.date(),
      acrDatetime: z.date(),
      creatinineDatetime: z.date(),
    })
    .optional(),
  nonDiabeticHyperglycaemia: z
    .object({
      hba1cValue: z.number(),
      hba1cDatetime: z.date(),
    })
    .optional(),
  cholesterol: z
    .object({
      completedTargets: z.array(z.enum(['QOF_CHOL003', 'QOF_CHOL004'])),
    })
    .optional(),
});
export type IAsyncReviewCriteria = z.infer<typeof ZAsyncReviewCriteria>;

const ZAsyncReviewTask = ZBaseTask.omit({
  task_type: true,
}).extend({
  task_type: z.literal('results_review_potentially_async'),
  asyncReviewCriteria: ZAsyncReviewCriteria,
  clinicalRiskCriteria: ZClinicalRiskCriteria.optional(),
});
export type IAsyncReviewTask = z.infer<typeof ZAsyncReviewTask>;

const ZResultsReviewTask = ZBaseTask.omit({
  task_type: true,
}).extend({
  task_type: z.literal('results_review'),
  clinicalRiskCriteria: ZClinicalRiskCriteria.optional(),
});
export type IResultsReviewTask = z.infer<typeof ZResultsReviewTask>;

const ZPartialResultsReviewTask = ZBaseTask.omit({
  task_type: true,
}).extend({
  task_type: z.literal('partial_results_review'),
  clinicalRiskCriteria: ZClinicalRiskCriteria.optional(),
});
export type IPartialResultsReviewTask = z.infer<
  typeof ZPartialResultsReviewTask
>;

export const ZTask = z.union([
  ZOtherTask,
  ZFormTask,
  ZSubmitTriageBPTask,
  ZSubmitBPTask,
  ZAsyncReviewTask,
  ZResultsReviewTask,
  ZPartialResultsReviewTask,
]);

export type ITask = z.infer<typeof ZTask>;
