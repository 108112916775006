import { z } from 'zod';
import camelcaseKeys from 'camelcase-keys';

export const ZPartnerDashboardName = z.object({
  first: z.string(),
  last: z.string(),
});

export type IPartnerDashboardName = z.infer<typeof ZPartnerDashboardName>;

export const ZDashboardPartner = z
  .object({
    _id: z.string(),
    name: ZPartnerDashboardName,
    practice: z.object({
      name: z.string(),
      lastSynced: z.string().optional(),
    }),
    featureFlags: z
      .object({
        'partner-planner': z.boolean(),
      })
      .optional(),
  })
  .transform((patient) => camelcaseKeys(patient));

export type IDashboardPartner = z.infer<typeof ZDashboardPartner>;

export const ZPartnerPatientDetails = z
  .object({
    _id: z.string(),
    name: ZPartnerDashboardName,
    nhs_number: z.string(),
    date_of_birth: z.string().optional(),
    date_of_last_sms: z.preprocess((data) => {
      if (typeof data === 'string') return new Date(data);
      // eslint-disable-next-line unicorn/no-null
      return null;
    }, z.date().nullable()),
    last_sms_content: z.string().nullable(),
    conditions: z.string().array(),
  })
  .transform((patient) => camelcaseKeys(patient));

export type IPartnerPatientDetails = z.infer<typeof ZPartnerPatientDetails>;

export const ZPartnerReferredPatient = z
  .object({
    _id: z.string(),
    name: ZPartnerDashboardName,
    nhs_number: z.string(),
    date_of_birth: z.string().optional(),
  })
  .transform((patient) => camelcaseKeys(patient));

export type IPartnerReferredPatient = z.infer<typeof ZPartnerReferredPatient>;
