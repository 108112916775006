import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

export function FindPatientOuterContainer({
  children,
}: PropsWithChildren<object>) {
  return (
    <Box
      sx={{
        bgcolor: 'colors.greyLightest',
        paddingY: '16px',
        paddingX: '16px',
        minHeight: 'calc(100vh - 48px)',
      }}
    >
      {children}
    </Box>
  );
}

export function FindPatientBox({ children }: PropsWithChildren<object>) {
  return (
    <Box
      sx={{
        marginX: 'auto',
        paddingX: '24px',
        paddingY: '16px',
        width: '100%',
        maxWidth: '548px',
        borderColor: 'colors.grey',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '8px',
        bgcolor: 'colors.white',
      }}
    >
      {children}
    </Box>
  );
}

export function FindPatientContainer({ children }: PropsWithChildren<object>) {
  return (
    <FindPatientOuterContainer>
      <FindPatientBox>{children}</FindPatientBox>
    </FindPatientOuterContainer>
  );
}
