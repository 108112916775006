// Generated by Avo VERSION 130.14.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function () {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  };

  function isDataView(obj: any) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function (obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name: any) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value: any) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items: any) {
    var iterator: any = {
      next: function () {
        var value = items.shift();
        return { done: value === undefined, value: value }
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function () {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers: any) {
    // @ts-ignore
    (this as any).map = {};

    if (headers instanceof Headers) {
      (headers as any).forEach(function (value: any, name: any) {
        // @ts-ignore
        this.append(name, value);
        // @ts-ignore
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function (header: any) {
        // @ts-ignore
        this.append(header[0], header[1]);
        // @ts-ignore
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function (name: any) {
        // @ts-ignore
        this.append(name, headers[name]);
        // @ts-ignore
      }, this);
    }
  }

  Headers.prototype.append = function (name: any, value: any) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function (name: any) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function (name: any) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function (name: any) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function (name: any, value: any) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function (callback: any, thisArg: any) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function () {
    var items: any = [];
    this.forEach(function (_value: any, name: any) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function () {
    var items: any = [];
    this.forEach(function (value: any) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function () {
    var items: any = [];
    this.forEach(function (value: any, name: any) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body: any) {
    if (body.bodyUsed) {
      return true;
    }
    body.bodyUsed = true;
    return false;
  }

  function fileReaderReady(reader: any) {
    return new Promise(function (resolve: any, reject: any) {
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function () {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf: any) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]!);
    }
    return chars.join('')
  }

  function bufferClone(buf: any) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    // @ts-ignore
    (this as any).bodyUsed = false;

    // @ts-ignore
    (this as any)._initBody = function (body: any) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      // @ts-ignore
      (this as any).blob = function () {
        var rejected = consumed(this);
        if (rejected) {
          return Promise.reject(new TypeError('Already read'));
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob);
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]));
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob');
        } else {
          return Promise.resolve(new Blob([this._bodyText]));
        }
      };

      // @ts-ignore
      (this as any).arrayBuffer = function () {
        if (this._bodyArrayBuffer) {
          if (consumed(this)) {
            return Promise.reject(new TypeError('Already read'));
          } else {
            return Promise.resolve(this._bodyArrayBuffer);
          }
        } else {
          return this.blob().then(readBlobAsArrayBuffer);
        }
      };
    }

    // @ts-ignore
    (this as any).text = function () {
      var rejected = consumed(this);
      if (rejected) {
        return Promise.reject(new TypeError('Already read'));
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob);
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer));
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text');
      } else {
        return Promise.resolve(this._bodyText);
      }
    };

    if (support.formData) {
      // @ts-ignore
      (this as any).formData = function () {
        return this.text().then(decode)
      };
    }

    // @ts-ignore
    (this as any).json = function () {
      return this.text().then(JSON.parse)
    };

    // @ts-ignore
    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method: any) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input: any, options: any) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if ((input as any).bodyUsed) {
        throw new TypeError('Already read')
      }
      // @ts-ignore
      (this as any).url = (input as any).url;
      // @ts-ignore
      this.credentials = (input as any).credentials;
      if (!options.headers) {
        // @ts-ignore
        this.headers = new Headers((input as any).headers);
      }
      // @ts-ignore
      this.method = (input as any).method;
      // @ts-ignore
      this.mode = (input as any).mode;
      // @ts-ignore
      this.signal = (input as any).signal;
      if (!body && (input as any)._bodyInit != null) {
        body = (input as any)._bodyInit;
        (input as any).bodyUsed = true;
      }
    } else {
      // @ts-ignore
      this.url = String(input);
    }

    // @ts-ignore
    this.credentials = options.credentials || this.credentials || 'same-origin';
    // @ts-ignore
    if (options.headers || !this.headers) {
      // @ts-ignore
      this.headers = new Headers(options.headers);
    }
    // @ts-ignore
    this.method = normalizeMethod(options.method || this.method || 'GET');
    // @ts-ignore
    this.mode = options.mode || this.mode || null;
    // @ts-ignore
    this.signal = options.signal || this.signal;
    // @ts-ignore
    this.referrer = null;

    // @ts-ignore
    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    // @ts-ignore
    this._initBody(body);
  }

  Request.prototype.clone = function () {
    // @ts-ignore
    return new Request(this, { body: this._bodyInit })
  };

  function decode(body: any) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function (bytes: any) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders: any) {
    // @ts-ignore
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function (line: any) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit: any, options: any) {
    if (!options) {
      options = {};
    }

    // @ts-ignore
    this.type = 'default';
    // @ts-ignore
    this.status = options.status === undefined ? 200 : options.status;
    // @ts-ignore
    this.ok = this.status >= 200 && this.status < 300;
    // @ts-ignore
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    // @ts-ignore
    this.headers = new Headers(options.headers);
    // @ts-ignore
    this.url = options.url || '';
    // @ts-ignore
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function () {
    // @ts-ignore
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      // @ts-ignore
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function () {
    // @ts-ignore
    var response = new Response(null, { status: 0, statusText: '' });
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function (url: any, status: any) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    // @ts-ignore
    return new Response(null, { status: status, headers: { location: url } })
  };

  (self as any).DOMException = (self as any).DOMException;
  try {
    new (self as any).DOMException();
  } catch (err) {
    (self as any).DOMException = function (message: any, name: any) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    (self as any).DOMException.prototype = Object.create(Error.prototype);
    (self as any).DOMException.prototype.constructor = (self as any).DOMException;
  }

  function fetch(input: any, init: any) {
    return new Promise(function (resolve, reject) {
      // @ts-ignore
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new (self as any).DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function () {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        (options as any).url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : (xhr as any).responseText;
        // @ts-ignore
        resolve(new Response(body, options));
      };

      xhr.onerror = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function () {
        reject(new (self as any).DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function (value: any, name: any) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function () {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    (self as any).fetch = fetch;
    (self as any).Headers = Headers;
    (self as any).Request = Request;
    (self as any).Response = Response;
  }
})();

export enum AvoEnv {
  Prod = "prod",
  Dev = "dev",
}

export interface CustomDestination {
  make?(env: AvoEnv, apiKey: string): void;
  logEvent?: (eventName: string, eventProperties: Record<string, any>) => void;
  setUserProperties?: (userId: string, userProperties: Record<string, any>) => void;
  identify?: (userId: string) => void;
  unidentify?: () => void;
  logPage?: (pageName: string, eventProperties: Record<string, any>) => void;
  revenue?: (amount: number, eventProperties: Record<string, any>) => void;
  setGroupProperties?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  addCurrentUserToGroup?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  logEventWithGroups?: (
    eventName: string,
    eventProperties: any,
    groupTypesToGroupIds: Record<string, string>,
  ) => void;
}

// @ts-ignore
interface AvoAssertMessage {
  eventName?: string;
  tag?: string;
  propertyId?: string;
  message?: string;
  additionalProperties?: string[],
  shape?: any,
  shapeUserProps?: any,
  actualType?: string
}

let __AVO_ENV__: AvoEnv | null = null;
// @ts-ignore
let __AVO_NOOP__: boolean = false;
// @ts-ignore
let __AVO_LOGGER__: AvoLogger | null = null;
// @ts-ignore
let __STRICT__: boolean | null = null;
// @ts-ignore
let __REPORT_FAILURE_AS__: 'error' | 'warn' | 'log' | null = null;

// @ts-ignore
let __WEB_DEBUGGER__: boolean = true;
export const avoInspectorApiKey = "NaCATuhXkNL4s1BAuLCN";
// @ts-ignore
interface AvoInspector {}
let __INSPECTOR__: AvoInspector | null = null;


// polyfill Object.assign
// @ts-ignore
declare interface ObjectConstructor {
  assign: any;
}
// @ts-ignore
if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target: any, _varArgs: any) { // .length of function is 2
      if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      let to = Object(target);

      for (let index = 1; index < arguments.length; index++) {
        let nextSource = arguments[index];

        if (nextSource != null) { // Skip over if undefined or null
          for (let nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

interface AvoLogger {
  logDebug(env: AvoEnv | null, message: string): boolean;
  logWarn(env: AvoEnv | null, message: string): boolean;
  logError(env: AvoEnv | null, error: string): boolean;
}

enum webDebuggerArea {
  BottomRight = "BottomRight",
  BottomLeft = "BottomLeft",
  TopRight = "TopRight",
  TopLeft = "TopLeft"
}

interface bottomRightParameters {
  bottom: number;
  right: number;
}

interface bottomLeftParameters {
  bottom: number;
  left: number;
}

interface topRightParameters {
  top: number;
  right: number;
}

interface topLeftParameters {
  top: number;
  left: number;
}

interface webDebuggerPosition {
  position: webDebuggerArea;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

interface webDebuggerOptions {
  position?: webDebuggerPosition;
}

interface WebDebuggerPositionSetter {
  BottomRight(p: bottomRightParameters): webDebuggerPosition;
  BottomLeft(p: bottomLeftParameters): webDebuggerPosition;
  TopRight(p: topRightParameters): webDebuggerPosition;
  TopLeft(p: topLeftParameters): webDebuggerPosition;
}

export const WebDebuggerPosition: WebDebuggerPositionSetter = {
  BottomRight: ({ bottom, right }) => ({
    position: webDebuggerArea.BottomRight,
    bottom,
    right,
  }),
  BottomLeft: ({ bottom, left }) => ({
    position: webDebuggerArea.BottomLeft,
    bottom,
    left,
  }),
  TopRight: ({ top, right }) => ({
    position: webDebuggerArea.TopRight,
    top,
    right,
  }),
  TopLeft: ({ top, left }) => ({
    position: webDebuggerArea.TopLeft,
    top,
    left,
  }),
}


let InternalAvoLogger: any = {
  logEventSent: function logEventSent(eventName: string, eventProperties: any, userProperties: any) {
    const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties);

    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties);
  },

  log: function log(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] " + message);
  },

  warn: function warn(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.warn("[avo] " + message);
  },

  error: function error(message: string, error: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
      return
    }
    typeof console !== 'undefined' && console.error("[avo] " + message, error);
  }
};

function convertPropertiesArrayToMap(propertiesArray: [{id: string, name: string, value: string}]): {string: string | null | undefined} {
    let result: {string: string} = {}

    propertiesArray.forEach(value => {
      result[value.name] = value.value
    })

    return result
}

// @ts-ignore
let array_difference: any;
// @ts-ignore
let AvoAssert: any;
array_difference = function array_difference(a1: any[], a2: any[]) {
  let result: any[] = [];
  for (let i = 0; i < a1.length; i++) {
    if (a2.indexOf(a1[i]) === -1) {
      result.push(a1[i]);
    }
  }
  return result;
}

AvoAssert = {
  assertObject: function assertObject(propertyId: string, propName: string, obj: any) {
    if (typeof obj !== 'object') {
      let message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
      return [{tag: 'expectedObjectType', propertyId, message, actualType: typeof obj}];
    } else {
      return [];
    }
  },

  assertString: function assertString(propertyId: string, propName: string, str: string) {
    if (typeof str !== 'string') {
      let message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
      return [{tag: 'expectedStringType', propertyId, message, actualType: typeof str}];
    } else {
      return [];
    }
  },

  assertInt: function assertInt(propertyId: string, propName: string, int: number) {
    if (typeof int === 'number' && int !== Math.round(int)) {
      let message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: 'float'}];
    } else if (typeof int !== 'number') {
      let message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: typeof int}];
    } else {
      return [];
    }
  },

  assertLong: function assertLong(propertyId: string, propName: string, long: number) {
    if (typeof long === 'number' && long !== Math.round(long)) {
      let message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: 'float'}];
    } else if (typeof long !== 'number') {
      let message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: typeof long}];
    } else {
      return [];
    }
  },

  assertFloat: function assertFloat(propertyId: string, propName: string, float: number) {
    if (typeof float !== 'number') {
      let message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
      return [{tag: 'expectedFloatType', propertyId, message, actualType: typeof float}];
    } else {
      return [];
    }
  },

  assertBool: function assertBool(propertyId: string, propName: string, bool: boolean) {
    if (typeof bool !== 'boolean') {
      let message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
      return [{tag: 'expectedBoolType', propertyId, message, actualType: typeof bool}];
    } else {
      return [];
    }
  },

  assertMax: function assertMax(
    propertyId: string,
    propName: string,
    max: number,
    value: number
  ) {
    if (value > max) {
      let message = propName +
        ' has a maximum value of ' +
        max +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMax', propertyId, message}];
    } else {
      return [];
    }
  },

  assertMin: function assertMin(
    propertyId: string,
    propName: string,
    min: number,
    value: number
  ) {
    if (value < min) {
      let message = propName +
        ' has a minimum value of ' +
        min +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMin', propertyId, message}];
    } else {
      return [];
    }
  },

  assertList: function assertList(propertyId: string, propName: string, value: any) {
    if (!Array.isArray(value)) {
      let message = propName + ' should be of type list but you provided type ' + typeof value;
      return [{tag: 'expectedList', propertyId, message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },
};

let _avo_invoke: any;
let _avo_invoke_meta: any;
let _avo_sampling_rate = 1.0;
_avo_invoke = function _avo_invoke(env: AvoEnv, eventId: string, hash: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "6u02K3BY65rrhMi5lkKd",
          "br": "master",
          "en": env,
          "ev": eventId,
          "ha": hash,
          "sc": "gSw6fFLOUFl03H8RlhRp",
          "se": (new Date()).toISOString(),
          "so": "gA8mWNshQ",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke_meta = function _avo_invoke_meta(env: AvoEnv, type: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "6u02K3BY65rrhMi5lkKd",
          "br": "master",
          "en": env,
          "ty": type,
          "sc": "gSw6fFLOUFl03H8RlhRp",
          "se": (new Date()).toISOString(),
          "so": "gA8mWNshQ",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}


let _avo_debugger_log: any;
let _avo_debugger_events_during_boot: any = [];
let _avo_debugger_ready = false;

if (typeof (window as any) !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    let iframe: any = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      let message = {
        type_: "avo-debugger-boot-events",
        schemaId: "gSw6fFLOUFl03H8RlhRp",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId: string, eventName: string, messages: any[], eventProperties: any[], userProperties: any[], groupProperties: any[]) {
  if (typeof (window as any) === 'undefined') { return; }
  let event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties,
    userProperties,
    groupProperties
  };

  if (_avo_debugger_ready) {
    let message = {type_: "avo-debugger-events", events: [event]};
    (document.getElementById("avo-debugger") as any).contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}

function _avo_debugger_send_position(position: webDebuggerPosition) {
  if (typeof window === 'undefined') { return; }
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    if (event.data.type_ === "avo-debugger-ready" && position !== null) {
      var message = {type_: "avo-debugger-position", position: position};
      document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  })
}


export const TargetAreaManagement = {
  'PRACTICE': 'practice',
  'SUVERA': 'suvera',
  'TO_BE_ONBOARDED': 'to-be-onboarded',
} as const;
export type TargetAreaManagementType = typeof TargetAreaManagement;
export type TargetAreaManagementValueType = TargetAreaManagementType[keyof TargetAreaManagementType];

export const TargetAreaStates = {
  'INCOMPLETE': 'incomplete',
  'COMPLETE': 'complete',
} as const;
export type TargetAreaStatesType = typeof TargetAreaStates;
export type TargetAreaStatesValueType = TargetAreaStatesType[keyof TargetAreaStatesType];

export const ReferralType = {
  'NEW_PATIENT': 'new_patient',
  'EXISTING_PATIENT': 'existing_patient',
} as const;
export type ReferralTypeType = typeof ReferralType;
export type ReferralTypeValueType = ReferralTypeType[keyof ReferralTypeType];

export const Tab = {
  'PAST_REFERRALS': 'past_referrals',
  'CREATE_REFERRAL': 'create_referral',
  'FIND_A_PATIENT': 'find_a_patient',
  'PLANNER': 'planner',
} as const;
export type TabType = typeof Tab;
export type TabValueType = TabType[keyof TabType];

let SegmentPartnerDashboard: any;

export function initAvo(options: {env: AvoEnv; webDebugger?: boolean;
  webDebuggerOptions?: webDebuggerOptions; strict?: boolean; noop?: boolean;
  reportFailureAs?: 'error' | 'warn' | 'log'; inspector?: AvoInspector;
  avoLogger?: AvoLogger}, destinationOptions: any,
  SegmentPartnerDashboardDestination: CustomDestination) {
  if (__AVO_ENV__ !== null) {
    return;
  }
  __AVO_ENV__ = options.env;
  if (options.avoLogger !== undefined) {
    __AVO_LOGGER__ = options.avoLogger;
  }
  if (options.noop === true) {
    __AVO_NOOP__ = true;
  }
  if (__AVO_NOOP__ && __AVO_ENV__ == AvoEnv.Prod) {
    InternalAvoLogger.warn("[avo] ****************************************************");
    InternalAvoLogger.warn("[avo] WARNING Avo cannot be initialized in noop mode in production:");
    InternalAvoLogger.warn("[avo] - Overwriting configuration with noop=false.");
    InternalAvoLogger.warn("[avo] - Please reach out if you want to be able to run Avo in production mode with noop=true");
    InternalAvoLogger.warn("[avo] ****************************************************");
    __AVO_NOOP__ = false;
  }
  if (__AVO_NOOP__) {
    InternalAvoLogger.log("[avo] ****************************************************");
    InternalAvoLogger.log("[avo] Avo is now initialized in noop mode. This means:");
    InternalAvoLogger.log("[avo] - No events will be sent");
    InternalAvoLogger.log("[avo] - No network requests are made");
    InternalAvoLogger.log("[avo] ****************************************************");
  }
  if (options.strict !== undefined) {
    __STRICT__ = options.strict !== false;
  }
  if (options.reportFailureAs !== undefined) {
    __REPORT_FAILURE_AS__ = options.reportFailureAs;
  }
  __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && (window as any).location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== AvoEnv.Prod));
  if (!__AVO_NOOP__ && options.inspector !== undefined) {
    __INSPECTOR__ = options.inspector;
  } else if (__AVO_ENV__ !== 'prod') {
    InternalAvoLogger.warn("[avo] Avo Inspector not provided in initAvo() call");
  }

  destinationOptions = destinationOptions || {};

  if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
    if (options.webDebuggerOptions?.position) {
      _avo_debugger_send_position(options.webDebuggerOptions.position)
    }

(function() {
  if (typeof (window as any) === 'undefined') { return; }
  let init = function() {
    let iframe: any = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.id = "avo-debugger";
    iframe.src = "https://www.avo.app/_debugger";
    iframe.style = "display: none;";
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();

  }
  if (!__AVO_NOOP__) {
    if (__AVO_ENV__ === AvoEnv.Prod) {
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
    }

    SegmentPartnerDashboard = SegmentPartnerDashboardDestination;
    if (__AVO_ENV__ === 'prod') {
      SegmentPartnerDashboard && SegmentPartnerDashboard.make && SegmentPartnerDashboard.make(__AVO_ENV__, "GVAnSne6uboFUXAB8Y36Un5YhikNEKiL");
    } else if (__AVO_ENV__ === 'dev') {
      SegmentPartnerDashboard && SegmentPartnerDashboard.make && SegmentPartnerDashboard.make(__AVO_ENV__, "Pa7CjAdrW8NPQHE0AhLAApLOjOdG7xkE");
    } else {
      console[__REPORT_FAILURE_AS__ || 'error']("[avo] No staging key is set for Segment - Partner Dashboard. Head to destination settings in Avo to set a staging key.");
      SegmentPartnerDashboard && SegmentPartnerDashboard.make && SegmentPartnerDashboard.make(__AVO_ENV__, null);
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
      // debug console in Avo
      _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
    }
  }
}

export function setAvoLogger(avoLogger: AvoLogger | null) {
  __AVO_LOGGER__ = avoLogger;
}

export interface PageViewedProperties {
  customDestinationPageName_: string;
}
/**
 * page_viewed: Segment event to track page views
 *
 * @param properties the properties associatied with this event
 * @param properties.customDestinationPageName_: Name of the page or screen
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/LEPzAikjq0}
 */
export function pageViewed(properties: PageViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "LEPzAikjq0", "67c075092fbe997877d543d634ebb05799478344df6eb2216608e9b889dcea84", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("page_viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("LEPzAikjq0", "page_viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    // destination SegmentPartnerDashboard
    if (SegmentPartnerDashboard.logPage) {
      SegmentPartnerDashboard.logPage(properties.customDestinationPageName_, (Object as any).assign({}, eventProperties));
    } else {
      InternalAvoLogger.error('Page method is not implemented in the destination', '');
    }
  } else {
    // do nothing
  }
}

/**
 * user_sign_in_completed: When a user completed signing in
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/-GqF0JXrRd}
 */
export function userSignInCompleted() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "-GqF0JXrRd", "32f3823edd01f49f8600ef1d4fcf594986883da5b8b2026591c97c061e737bb7", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("user_sign_in_completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("-GqF0JXrRd", "user_sign_in_completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("user_sign_in_completed", eventProperties, "-GqF0JXrRd", "32f3823edd01f49f8600ef1d4fcf594986883da5b8b2026591c97c061e737bb7");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("user_sign_in_completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface UserIdentifiedProperties {
  userId_: string;
}
/**
 * user_identified: Segment event to identify who the subsequent events came from
 *
 * @param properties the properties associatied with this event
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/P2HUZdS0TV}
 */
export function userIdentified(properties: UserIdentifiedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "P2HUZdS0TV", "231383d810b1c4cecaf025f9a485c3163fe7319c79b7057ebd3f99eb595bc845", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("user_identified", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("P2HUZdS0TV", "user_identified", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.identify(properties.userId_);
  } else {
    // do nothing
  }
}

/**
 * user_signed_out: When a user signed out
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/rmKPeo_ncL}
 */
export function userSignedOut() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "rmKPeo_ncL", "14c9d0994e1ecb60b60f48ff24d69a1d25b87327b7ec0ccc5463224307b6e13d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("user_signed_out", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("rmKPeo_ncL", "user_signed_out", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("user_signed_out", eventProperties, "rmKPeo_ncL", "14c9d0994e1ecb60b60f48ff24d69a1d25b87327b7ec0ccc5463224307b6e13d");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("user_signed_out", (Object as any).assign({}, eventProperties));
    SegmentPartnerDashboard.unidentify();
  } else {
    // do nothing
  }
}

export interface NavigationButtonClickedProperties {
  tab: TabValueType;
}
/**
 * navigation_button_clicked: A user clicked on one of the navigation buttons in the header
 *
 * @param properties the properties associatied with this event
 * @param properties.tab: tab/page in partner dashboard
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/E1PSoVwaPL}
 */
export function navigationButtonClicked(
  properties: NavigationButtonClickedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "VjDXDxMDcY", name: "tab", value: properties.tab});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "E1PSoVwaPL", "7ce69eca50b11ff52a2eafb59e2c008493be483a0883872f62767d0f0756880e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("navigation_button_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("E1PSoVwaPL", "navigation_button_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("navigation_button_clicked", eventProperties, "E1PSoVwaPL", "7ce69eca50b11ff52a2eafb59e2c008493be483a0883872f62767d0f0756880e");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("navigation_button_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * phone_link_clicked: A user clicked on a link with our phone number
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/oOVPSZMmV8}
 */
export function phoneLinkClicked() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "oOVPSZMmV8", "d87b5269c4af783a3b10af296c24cecdb27f7de8e31d9c23a5232b32233c0b7f", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("phone_link_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("oOVPSZMmV8", "phone_link_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("phone_link_clicked", eventProperties, "oOVPSZMmV8", "d87b5269c4af783a3b10af296c24cecdb27f7de8e31d9c23a5232b32233c0b7f");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("phone_link_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * create_referral_back_button_clicked: A user clicked the button to go back from the create-referral confirmation screen to the create-referral form
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/WQhRAh8wyw}
 */
export function createReferralBackButtonClicked() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "WQhRAh8wyw", "2a0c618b7f0deb9ca4fcb4c051f9b321e32b7c9234380635acafe1460a533007", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("create_referral_back_button_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("WQhRAh8wyw", "create_referral_back_button_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("create_referral_back_button_clicked", eventProperties, "WQhRAh8wyw", "2a0c618b7f0deb9ca4fcb4c051f9b321e32b7c9234380635acafe1460a533007");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("create_referral_back_button_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CreateReferralCompletedProperties {
  referralType: ReferralTypeValueType;
  noteIsIncluded: boolean;
}
/**
 * create_referral_completed: A user clicked the submit button at the end of the create-referral journey
 *
 * @param properties the properties associatied with this event
 * @param properties.referralType: was the referral for a new patient or for one already known to us
 * @param properties.noteIsIncluded: was a note included with the form submission?
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/To5VwjVZZR}
 */
export function createReferralCompleted(
  properties: CreateReferralCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "KcIyZQ7cj", name: "referral_type", value: properties.referralType});
  eventPropertiesArray.push({id: "UmQJaRHj4l", name: "note_is_included", value: properties.noteIsIncluded});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "To5VwjVZZR", "2e7be16478c876a7f7715b710875764067995f85f47913fb4423f52e9c390033", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("create_referral_completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("To5VwjVZZR", "create_referral_completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("create_referral_completed", eventProperties, "To5VwjVZZR", "2e7be16478c876a7f7715b710875764067995f85f47913fb4423f52e9c390033");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("create_referral_completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CreateReferralFormFieldTouchedProperties {
  formFieldName: string;
}
/**
 * create_referral_form_field_touched: When partner starts interacting with an input field on the create referral form
 *
 * @param properties the properties associatied with this event
 * @param properties.formFieldName: The name of the form field being interacted with
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/UE2CAqK0Ch}
 */
export function createReferralFormFieldTouched(
  properties: CreateReferralFormFieldTouchedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "a0wv7BTGKw", name: "form_field_name", value: properties.formFieldName});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "UE2CAqK0Ch", "2795004f4367e67eb3288f7e4cfd40748794de62e5a2e0b6df878453f8bce16d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("create_referral_form_field_touched", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("UE2CAqK0Ch", "create_referral_form_field_touched", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("create_referral_form_field_touched", eventProperties, "UE2CAqK0Ch", "2795004f4367e67eb3288f7e4cfd40748794de62e5a2e0b6df878453f8bce16d");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("create_referral_form_field_touched", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * create_referral_next_button_clicked: When partner clicks the next button on create referral form
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/bC_0mftrHY}
 */
export function createReferralNextButtonClicked() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "bC_0mftrHY", "6d8fcdb6200e35516fba2439fbb137c2ebd1fd88711c71fec39f1d5f6f20e7f2", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("create_referral_next_button_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("bC_0mftrHY", "create_referral_next_button_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("create_referral_next_button_clicked", eventProperties, "bC_0mftrHY", "6d8fcdb6200e35516fba2439fbb137c2ebd1fd88711c71fec39f1d5f6f20e7f2");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("create_referral_next_button_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * create_referral_success_message_seen: The backend request to create a referral succeeded
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/i9LPl9UqvE}
 */
export function createReferralSuccessMessageSeen() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "i9LPl9UqvE", "deef22b977f597d534c5c53d65876c7994648b862b20abdf5305b35e5f807a0f", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("create_referral_success_message_seen", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("i9LPl9UqvE", "create_referral_success_message_seen", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("create_referral_success_message_seen", eventProperties, "i9LPl9UqvE", "deef22b977f597d534c5c53d65876c7994648b862b20abdf5305b35e5f807a0f");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("create_referral_success_message_seen", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * create_referral_error_message_seen: The backend request to create a referral failed
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/KnI3PSQftH}
 */
export function createReferralErrorMessageSeen() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "KnI3PSQftH", "4ea002fa6203342e8c9d25b34888efd8e6248d37b62039e5f8e0a8bc2f1a106e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("create_referral_error_message_seen", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("KnI3PSQftH", "create_referral_error_message_seen", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("create_referral_error_message_seen", eventProperties, "KnI3PSQftH", "4ea002fa6203342e8c9d25b34888efd8e6248d37b62039e5f8e0a8bc2f1a106e");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("create_referral_error_message_seen", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * feedback_form_opened: The feedback form in the help section is opened
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/C0Tlf812Tq}
 */
export function feedbackFormOpened() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "C0Tlf812Tq", "752d7b603cb81aa4181c710bcd9ebcb4b559597f8b1604e611d2f6f98f1ac7a7", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("feedback_form_opened", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("C0Tlf812Tq", "feedback_form_opened", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("feedback_form_opened", eventProperties, "C0Tlf812Tq", "752d7b603cb81aa4181c710bcd9ebcb4b559597f8b1604e611d2f6f98f1ac7a7");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("feedback_form_opened", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * past_referrals_expand_row_button_clicked: No description
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/OqUXePE86b}
 */
export function pastReferralsExpandRowButtonClicked() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "OqUXePE86b", "e7780b35812a248eb38a612c3ffabbc2861508fb3de8e2cb35dc4809c7c4de98", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("past_referrals_expand_row_button_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("OqUXePE86b", "past_referrals_expand_row_button_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("past_referrals_expand_row_button_clicked", eventProperties, "OqUXePE86b", "e7780b35812a248eb38a612c3ffabbc2861508fb3de8e2cb35dc4809c7c4de98");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("past_referrals_expand_row_button_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PastReferralsPageErrorStateSeenProperties {
  httpStatus: number | null | undefined;
  errorMessage: string | null | undefined;
}
/**
 * past_referrals_page_error_state_seen: would generally result from a server side error
 *
 * @param properties the properties associatied with this event
 * @param properties.httpStatus: no description
 * @param properties.errorMessage: no description
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/IEkmDhS579}
 */
export function pastReferralsPageErrorStateSeen(
  properties: PastReferralsPageErrorStateSeenProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.httpStatus !== undefined && properties.httpStatus !== null ?
    eventPropertiesArray.push({id: "X_wutcSXE3", name: "http_status", value: properties.httpStatus}) :
    eventPropertiesArray.push({id: "X_wutcSXE3", name: "http_status", value: null});
  properties.errorMessage !== undefined && properties.errorMessage !== null ?
    eventPropertiesArray.push({id: "FvaCpORi0E", name: "error_message", value: properties.errorMessage}) :
    eventPropertiesArray.push({id: "FvaCpORi0E", name: "error_message", value: null});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "IEkmDhS579", "78f3a682b43867af69914555d413d37d33a07dc544cb609f200195b98e247d1b", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("past_referrals_page_error_state_seen", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("IEkmDhS579", "past_referrals_page_error_state_seen", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("past_referrals_page_error_state_seen", eventProperties, "IEkmDhS579", "78f3a682b43867af69914555d413d37d33a07dc544cb609f200195b98e247d1b");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("past_referrals_page_error_state_seen", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * unknown_user_screen_seen: user saw an error message saying that we could not authenticate
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/Cu_IPrp-dD}
 */
export function unknownUserScreenSeen() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Cu_IPrp-dD", "55f18e9fa66c1bd90164b45184e46928b28bb9e51ffdaa0810ac805f846f9c8d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("unknown_user_screen_seen", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Cu_IPrp-dD", "unknown_user_screen_seen", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("unknown_user_screen_seen", eventProperties, "Cu_IPrp-dD", "55f18e9fa66c1bd90164b45184e46928b28bb9e51ffdaa0810ac805f846f9c8d");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("unknown_user_screen_seen", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FindAPatientFormFieldTouchedProperties {
  formFieldName: string;
}
/**
 * find_a_patient_form_field_touched: When partner starts interacting with the input field
 *
 * @param properties the properties associatied with this event
 * @param properties.formFieldName: The name of the form field being interacted with
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/9OAbxhNE6K}
 */
export function findAPatientFormFieldTouched(
  properties: FindAPatientFormFieldTouchedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "a0wv7BTGKw", name: "form_field_name", value: properties.formFieldName});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "9OAbxhNE6K", "fee303650932a1a9226b31d34e3f636387cb5c4d7dbaf8f94cca00e7bd2f4eb3", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("find_a_patient_form_field_touched", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("9OAbxhNE6K", "find_a_patient_form_field_touched", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("find_a_patient_form_field_touched", eventProperties, "9OAbxhNE6K", "fee303650932a1a9226b31d34e3f636387cb5c4d7dbaf8f94cca00e7bd2f4eb3");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("find_a_patient_form_field_touched", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * find_a_patient_search_button_clicked: When partner clicks the search button on the find patient form
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/zGg1p8UWJe}
 */
export function findAPatientSearchButtonClicked() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "zGg1p8UWJe", "b4dad780d05b7b2a8ac17e70570806b0383c823bf46124bac0dd2b80519f81dd", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("find_a_patient_search_button_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("zGg1p8UWJe", "find_a_patient_search_button_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("find_a_patient_search_button_clicked", eventProperties, "zGg1p8UWJe", "b4dad780d05b7b2a8ac17e70570806b0383c823bf46124bac0dd2b80519f81dd");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("find_a_patient_search_button_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FindAPatientValidationErrorMessageSeenProperties {
  formFieldName: string;
}
/**
 * find_a_patient_validation_error_message_seen: When user types in the NHS number that contains validation errors
 *
 * @param properties the properties associatied with this event
 * @param properties.formFieldName: The name of the form field being interacted with
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/O5xb-8zZuI}
 */
export function findAPatientValidationErrorMessageSeen(
  properties: FindAPatientValidationErrorMessageSeenProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "a0wv7BTGKw", name: "form_field_name", value: properties.formFieldName});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "O5xb-8zZuI", "9b38cd1d273b22200ba2e40cd8efd4ddfbadb7cf3ddc5c6b5c016ea52c615835", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("find_a_patient_validation_error_message_seen", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("O5xb-8zZuI", "find_a_patient_validation_error_message_seen", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("find_a_patient_validation_error_message_seen", eventProperties, "O5xb-8zZuI", "9b38cd1d273b22200ba2e40cd8efd4ddfbadb7cf3ddc5c6b5c016ea52c615835");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("find_a_patient_validation_error_message_seen", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * find_a_patient_create_referral_button_clicked: When user clicks the "Create referral" button on the Find A Patient page
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/fzGgde7sPa}
 */
export function findAPatientCreateReferralButtonClicked() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "fzGgde7sPa", "364b6adee7368bc1c3cef8cef3149a526c807211dbefdf91a757bd2159da2049", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("find_a_patient_create_referral_button_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("fzGgde7sPa", "find_a_patient_create_referral_button_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("find_a_patient_create_referral_button_clicked", eventProperties, "fzGgde7sPa", "364b6adee7368bc1c3cef8cef3149a526c807211dbefdf91a757bd2159da2049");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("find_a_patient_create_referral_button_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * find_a_patient_back_button_clicked: When user clicks the back button on the Find A Patient page
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/nATtLMM8k}
 */
export function findAPatientBackButtonClicked() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "nATtLMM8k", "a591f05cb7d25cd4089a406aebfe5e63dfe1c5e6e705731c682895f5022b466e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("find_a_patient_back_button_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("nATtLMM8k", "find_a_patient_back_button_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("find_a_patient_back_button_clicked", eventProperties, "nATtLMM8k", "a591f05cb7d25cd4089a406aebfe5e63dfe1c5e6e705731c682895f5022b466e");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("find_a_patient_back_button_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * find_a_patient_new_patient_details_viewed: When user gets shown new patient details
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/KXncuZMo2o}
 */
export function findAPatientNewPatientDetailsViewed() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "KXncuZMo2o", "f5bfecf79f77bbb699cbcaf87003786cb7b3f0d73919ee4cfb35cf77df84f698", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("find_a_patient_new_patient_details_viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("KXncuZMo2o", "find_a_patient_new_patient_details_viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("find_a_patient_new_patient_details_viewed", eventProperties, "KXncuZMo2o", "f5bfecf79f77bbb699cbcaf87003786cb7b3f0d73919ee4cfb35cf77df84f698");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("find_a_patient_new_patient_details_viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * find_a_patient_existing_patient_details_viewed: When user gets shown existing patient details
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/HAjTP20Td5}
 */
export function findAPatientExistingPatientDetailsViewed() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "HAjTP20Td5", "f6cfcce490c886d9e52e85f7ccb8d718461cd40c25ee8446d39595799c2b2655", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("find_a_patient_existing_patient_details_viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("HAjTP20Td5", "find_a_patient_existing_patient_details_viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("find_a_patient_existing_patient_details_viewed", eventProperties, "HAjTP20Td5", "f6cfcce490c886d9e52e85f7ccb8d718461cd40c25ee8446d39595799c2b2655");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("find_a_patient_existing_patient_details_viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * help_modal_opened: When partner closes the help modal
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/8rpAPkSiS}
 */
export function helpModalOpened() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "8rpAPkSiS", "50264d86bdaf4d0e17cbe856987e764404effd9c36ec1ee928f2ee2de4d19892", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("help_modal_opened", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("8rpAPkSiS", "help_modal_opened", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("help_modal_opened", eventProperties, "8rpAPkSiS", "50264d86bdaf4d0e17cbe856987e764404effd9c36ec1ee928f2ee2de4d19892");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("help_modal_opened", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * help_modal_closed: When partner opens the help modal
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/8LsWMj08r}
 */
export function helpModalClosed() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "8LsWMj08r", "df0dc5a70a2f9067567d04e9f0262f7cf3b32810e601f7f3f8bc5b6cdd4c656d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("help_modal_closed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("8LsWMj08r", "help_modal_closed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("help_modal_closed", eventProperties, "8LsWMj08r", "df0dc5a70a2f9067567d04e9f0262f7cf3b32810e601f7f3f8bc5b6cdd4c656d");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("help_modal_closed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * uclp_risk_information_button_clicked: when a partner clicks the UCLP risk link which opens the notion page "A guide to planner"
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/9riQWBgRyt}
 */
export function uclpRiskInformationButtonClicked() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "9riQWBgRyt", "74e2129adf8cae962c8009b4cd3c62b3e717465508c7692cb69679e1fda166a0", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("uclp_risk_information_button_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("9riQWBgRyt", "uclp_risk_information_button_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("uclp_risk_information_button_clicked", eventProperties, "9riQWBgRyt", "74e2129adf8cae962c8009b4cd3c62b3e717465508c7692cb69679e1fda166a0");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("uclp_risk_information_button_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * patient_view_button_clicked: "View" button clicked from planner patient list to open up an individual patient
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/O_WdWoZyLr}
 */
export function patientViewButtonClicked() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "O_WdWoZyLr", "dc722be2ac774e667537cf5f6616243f4ca60c864a55272293b1f35b5b7cbbd2", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("patient_view_button_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("O_WdWoZyLr", "patient_view_button_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("patient_view_button_clicked", eventProperties, "O_WdWoZyLr", "dc722be2ac774e667537cf5f6616243f4ca60c864a55272293b1f35b5b7cbbd2");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("patient_view_button_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * planner_last_updated_banner_viewed: When partner sees the "Planner last updated" banner
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/WkhHlO75FA}
 */
export function plannerLastUpdatedBannerViewed() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "WkhHlO75FA", "ebd195cdadd8ca912706395546e159851dd66c30396dd8f0c0c8ff4eb80112e0", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("planner_last_updated_banner_viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("WkhHlO75FA", "planner_last_updated_banner_viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("planner_last_updated_banner_viewed", eventProperties, "WkhHlO75FA", "ebd195cdadd8ca912706395546e159851dd66c30396dd8f0c0c8ff4eb80112e0");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("planner_last_updated_banner_viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * planner_last_updated_banner_closed: When partner closes the "Last updated" banner
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/su9hiOG25I}
 */
export function plannerLastUpdatedBannerClosed() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "su9hiOG25I", "5d9462f428957be32cc85e5a1519b58f869ecdb5c0747ac1d7a2a152021dc260", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("planner_last_updated_banner_closed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("su9hiOG25I", "planner_last_updated_banner_closed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("planner_last_updated_banner_closed", eventProperties, "su9hiOG25I", "5d9462f428957be32cc85e5a1519b58f869ecdb5c0747ac1d7a2a152021dc260");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("planner_last_updated_banner_closed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface TargetAreaManagementFilterUpdatedProperties {
  targetAreaManagement: TargetAreaManagementValueType;
}
/**
 * target_area_management_filter_updated: When partner updates the Target Area Management filter
 *
 * @param properties the properties associatied with this event
 * @param properties.targetAreaManagement: no description
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/t276LINQqO}
 */
export function targetAreaManagementFilterUpdated(
  properties: TargetAreaManagementFilterUpdatedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "6GxvVX72KU", name: "target_area_management", value: properties.targetAreaManagement});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "t276LINQqO", "81fcf84f54756dd5f8022af55463d4d90e1b33eb9074ab0ba94842a03749caa7", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("target_area_management_filter_updated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("t276LINQqO", "target_area_management_filter_updated", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("target_area_management_filter_updated", eventProperties, "t276LINQqO", "81fcf84f54756dd5f8022af55463d4d90e1b33eb9074ab0ba94842a03749caa7");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("target_area_management_filter_updated", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface TargetAreaStatesFilterUpdatedProperties {
  targetAreaStates: TargetAreaStatesValueType[];
}
/**
 * target_area_states_filter_updated: When partner updates the Target Area States filter
 *
 * @param properties the properties associatied with this event
 * @param properties.targetAreaStates: no description
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/lbQLVSDlvx}
 */
export function targetAreaStatesFilterUpdated(
  properties: TargetAreaStatesFilterUpdatedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "Aw7jdeyJB", name: "target_area_states", value: properties.targetAreaStates});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "lbQLVSDlvx", "724f53ccaca420946bef3a24174ec953ce8f2ae443396d7e5051a9a81b217f87", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("target_area_states_filter_updated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("lbQLVSDlvx", "target_area_states_filter_updated", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("target_area_states_filter_updated", eventProperties, "lbQLVSDlvx", "724f53ccaca420946bef3a24174ec953ce8f2ae443396d7e5051a9a81b217f87");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("target_area_states_filter_updated", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * reset_filters_button_clicked: When partner clicks reset filters button
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/uP8SdzaUYp}
 */
export function resetFiltersButtonClicked() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "uP8SdzaUYp", "e1da03b2b646154467b50b6e286da2e99e8a3274ee9e9c85bacc9f1dd61508cc", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("reset_filters_button_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("uP8SdzaUYp", "reset_filters_button_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("reset_filters_button_clicked", eventProperties, "uP8SdzaUYp", "e1da03b2b646154467b50b6e286da2e99e8a3274ee9e9c85bacc9f1dd61508cc");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("reset_filters_button_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * export_list_button_clicked: When partner clicks the "Export list" button
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/2IYCtepewr}
 */
export function exportListButtonClicked() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "2IYCtepewr", "58e8d78c17e76e93afcd052e77238c3ffae30824e0481585871f92548e5ee93c", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("export_list_button_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("2IYCtepewr", "export_list_button_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("export_list_button_clicked", eventProperties, "2IYCtepewr", "58e8d78c17e76e93afcd052e77238c3ffae30824e0481585871f92548e5ee93c");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("export_list_button_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * export_list_error_message_viewed: When partner sees an error message upon a failed list export
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/J7KOUR82kM}
 */
export function exportListErrorMessageViewed() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "J7KOUR82kM", "e04cc12c575ce0ec85d10e022983608588febf62262c3cbf0747b907e74cca1b", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("export_list_error_message_viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("J7KOUR82kM", "export_list_error_message_viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("export_list_error_message_viewed", eventProperties, "J7KOUR82kM", "e04cc12c575ce0ec85d10e022983608588febf62262c3cbf0747b907e74cca1b");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("export_list_error_message_viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface MonthsOfBirthFilterUpdatedProperties {
  monthsOfBirth: string[];
}
/**
 * months_of_birth_filter_updated: When partner updates the months of birth filter
 *
 * @param properties the properties associatied with this event
 * @param properties.monthsOfBirth: no description
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/sWNnXXw0nU}
 */
export function monthsOfBirthFilterUpdated(
  properties: MonthsOfBirthFilterUpdatedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "qSpBJ2g7I", name: "months_of_birth", value: properties.monthsOfBirth});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "sWNnXXw0nU", "f1d6ff82398cd5b040ca9e2b4789cb195b7895891c009582936b3910d53359ec", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("months_of_birth_filter_updated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("sWNnXXw0nU", "months_of_birth_filter_updated", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("months_of_birth_filter_updated", eventProperties, "sWNnXXw0nU", "f1d6ff82398cd5b040ca9e2b4789cb195b7895891c009582936b3910d53359ec");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("months_of_birth_filter_updated", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * planner_next_page_button_clicked: When partner clicks the next page button
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/fKkcfyNiOn}
 */
export function plannerNextPageButtonClicked() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "fKkcfyNiOn", "65dab6d99191d762eddc5d682255f95e63e1f658abb2cdacea99cca03fbbe911", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("planner_next_page_button_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("fKkcfyNiOn", "planner_next_page_button_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("planner_next_page_button_clicked", eventProperties, "fKkcfyNiOn", "65dab6d99191d762eddc5d682255f95e63e1f658abb2cdacea99cca03fbbe911");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("planner_next_page_button_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * planner_previous_page_button_clicked: When partner clicks the previous page button
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/E9X0Jfca27}
 */
export function plannerPreviousPageButtonClicked() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "E9X0Jfca27", "bb1ec03533554db45ad5240d9d1cd0a203c4c10974e263318291537005b2c24b", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("planner_previous_page_button_clicked", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("E9X0Jfca27", "planner_previous_page_button_clicked", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("planner_previous_page_button_clicked", eventProperties, "E9X0Jfca27", "bb1ec03533554db45ad5240d9d1cd0a203c4c10974e263318291537005b2c24b");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("planner_previous_page_button_clicked", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * export_list_success_message_viewed: When partner sees a success message upon a successful CSV file download
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/kWGs4_SNci}
 */
export function exportListSuccessMessageViewed() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "kWGs4_SNci", "e2ab712ed372816493c14607186084c592afa60de27fdeb5ff6777e48caecab5", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("export_list_success_message_viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("kWGs4_SNci", "export_list_success_message_viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("export_list_success_message_viewed", eventProperties, "kWGs4_SNci", "e2ab712ed372816493c14607186084c592afa60de27fdeb5ff6777e48caecab5");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("export_list_success_message_viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * patient_view_last_sms_modal_opened: When partner opens the last sms modal on the patient view
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/O590p5zpAE}
 */
export function patientViewLastSmsModalOpened() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "O590p5zpAE", "2ae2cb2b73a2cfa041353db693a5af42f6eef99c92a1eee063639ecd31dbf213", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("patient_view_last_sms_modal_opened", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("O590p5zpAE", "patient_view_last_sms_modal_opened", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("patient_view_last_sms_modal_opened", eventProperties, "O590p5zpAE", "2ae2cb2b73a2cfa041353db693a5af42f6eef99c92a1eee063639ecd31dbf213");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("patient_view_last_sms_modal_opened", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * patient_view_last_sms_modal_closed: When partner closes the last sms modal on the patient view
 *
 * @see {@link https://www.avo.app/schemas/gSw6fFLOUFl03H8RlhRp/events/biU5w52VEN}
 */
export function patientViewLastSmsModalClosed() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "biU5w52VEN", "3fb4e6c5330f0a4f0e43ec85fa54e15c7a0570f89c852f964e468a3a96a18c83", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("patient_view_last_sms_modal_closed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("biU5w52VEN", "patient_view_last_sms_modal_closed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("patient_view_last_sms_modal_closed", eventProperties, "biU5w52VEN", "3fb4e6c5330f0a4f0e43ec85fa54e15c7a0570f89c852f964e468a3a96a18c83");
    }
    // destination SegmentPartnerDashboard
    SegmentPartnerDashboard.logEvent("patient_view_last_sms_modal_closed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export default {
  AvoEnv,
  initAvo,
  avoInspectorApiKey,
  TargetAreaManagement,
  TargetAreaStates,
  ReferralType,
  Tab,
  pageViewed,
  userSignInCompleted,
  userIdentified,
  userSignedOut,
  navigationButtonClicked,
  phoneLinkClicked,
  createReferralBackButtonClicked,
  createReferralCompleted,
  createReferralFormFieldTouched,
  createReferralNextButtonClicked,
  createReferralSuccessMessageSeen,
  createReferralErrorMessageSeen,
  feedbackFormOpened,
  pastReferralsExpandRowButtonClicked,
  pastReferralsPageErrorStateSeen,
  unknownUserScreenSeen,
  findAPatientFormFieldTouched,
  findAPatientSearchButtonClicked,
  findAPatientValidationErrorMessageSeen,
  findAPatientCreateReferralButtonClicked,
  findAPatientBackButtonClicked,
  findAPatientNewPatientDetailsViewed,
  findAPatientExistingPatientDetailsViewed,
  helpModalOpened,
  helpModalClosed,
  uclpRiskInformationButtonClicked,
  patientViewButtonClicked,
  plannerLastUpdatedBannerViewed,
  plannerLastUpdatedBannerClosed,
  targetAreaManagementFilterUpdated,
  targetAreaStatesFilterUpdated,
  resetFiltersButtonClicked,
  exportListButtonClicked,
  exportListErrorMessageViewed,
  monthsOfBirthFilterUpdated,
  plannerNextPageButtonClicked,
  plannerPreviousPageButtonClicked,
  exportListSuccessMessageViewed,
  patientViewLastSmsModalOpened,
  patientViewLastSmsModalClosed,
}

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["pageViewed","userSignInCompleted","userIdentified","userSignedOut","navigationButtonClicked","phoneLinkClicked","createReferralBackButtonClicked","createReferralCompleted","createReferralFormFieldTouched","createReferralNextButtonClicked","createReferralSuccessMessageSeen","createReferralErrorMessageSeen","feedbackFormOpened","pastReferralsExpandRowButtonClicked","pastReferralsPageErrorStateSeen","unknownUserScreenSeen","findAPatientFormFieldTouched","findAPatientSearchButtonClicked","findAPatientValidationErrorMessageSeen","findAPatientCreateReferralButtonClicked","findAPatientBackButtonClicked","findAPatientNewPatientDetailsViewed","findAPatientExistingPatientDetailsViewed","helpModalOpened","helpModalClosed","uclpRiskInformationButtonClicked","patientViewButtonClicked","plannerLastUpdatedBannerViewed","plannerLastUpdatedBannerClosed","targetAreaManagementFilterUpdated","targetAreaStatesFilterUpdated","resetFiltersButtonClicked","exportListButtonClicked","exportListErrorMessageViewed","monthsOfBirthFilterUpdated","plannerNextPageButtonClicked","plannerPreviousPageButtonClicked","exportListSuccessMessageViewed","patientViewLastSmsModalOpened","patientViewLastSmsModalClosed"]
