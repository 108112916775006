export const ROUTES = {
  create_referral: '/create-a-new-referral',
  past_referrals: '/referrals',
  logout: '/logout',
  find_a_patient: '/find-a-patient',
  homepage: '/',
  planner: '/planner',
  planner_patient: '/planner/patient',
};

export const getPatientRoute = (_id: string): string =>
  `${ROUTES.planner_patient}/${_id}`;
