import { z } from 'zod';
import { ZDateString } from './date';
import { ZObjectId } from './objectid';

/**
 * This file contains zod types (and subsequent inferred types) for patient target data
 * Many of these have a 'Patient' and 'EHR' variant, since the EHR patient will pass back single object
 * And the patient will store arrays of these - so the types vary slightly
 */

// Need these as arrays so can iterate through in the code
export const TargetPrefixList = [
  'QOF',
  'SUV',
  'NWLICS',
  'NOVARTIS',
  'DQIST',
  'NCD',
] as const;
type TargetPrefix = (typeof TargetPrefixList)[number];

type TargetSuffix = string;
export type TargetName = `${TargetPrefix}_${TargetSuffix}`;

// Main mapping of target areas to targets names
// Ensure these are capitalised, spaced and hyphenated properly
export const TARGET_AREA_LABELS = {
  hypertension: 'Hypertension',
  hypertensionCaseFinding: 'Hypertension case-finding',
  diabetes: 'Type 2 diabetes',
  nonDiabeticHyperglycaemia: 'Non-diabetic hyperglycaemia',
  cholesterol: 'Cholesterol',
  frailty: 'Frailty',
  structuredMedicationReview: 'Structured medication review',
  asthma: 'Asthma',
  copd: 'COPD',
  referralPatient: 'Referral patient',
} as const;

// Utility type for getting keys from TARGET_AREA_LABELS as literals
export type TargetAreaKey = keyof typeof TARGET_AREA_LABELS;

export const targetAreaKeys = [
  'hypertension',
  'hypertensionCaseFinding',
  'diabetes',
  'nonDiabeticHyperglycaemia',
  'cholesterol',
  'frailty',
  'structuredMedicationReview',
  'asthma',
  'copd',
  'referralPatient',
] as const;

export type TargetAreaName = (typeof targetAreaKeys)[number];

export const ZTargetAreaKeys = z.enum(targetAreaKeys);

export type TargetNames = (typeof TargetAreas)[TargetAreaKey][number];

export const TargetAreas = {
  hypertension: ['QOF_HYP008', 'QOF_HYP009'],
  diabetes: [
    'QOF_DM006',
    'QOF_DM012',
    'QOF_DM014',
    'QOF_DM020',
    'QOF_DM021',
    'QOF_DM022',
    'QOF_DM023',
    'QOF_DM033',
    'DQIST_ALL001',
    'DQIST_BP001',
    'DQIST_CHOL001',
    'DQIST_HBA1C001',
  ],
  hypertensionCaseFinding: ['SUV_HYPCF01i', 'SUV_HYPCF01ii'],
  nonDiabeticHyperglycaemia: [
    'SUV_NDH001',
    'SUV_NDH002',
    'QOF_NDH002',
    'NWLICS_DH003',
    'NWLICS_DH004',
    'NWLICS_DH004a',
    'NWLICS_DH004b',
    'NWLICS_DH004c',
    'NWLICS_DH004d',
    'NWLICS_DH004e',
    'NWLICS_DH004f',
    'NWLICS_DH004g',
  ],
  cholesterol: [
    'QOF_CHOL003',
    'QOF_CHOL004',
    'NOVARTIS_COHORT001a',
    'NOVARTIS_COHORT001b',
    'NOVARTIS_COHORT004a',
    'NOVARTIS_COHORT004b',
  ],
  frailty: ['NWLICS_PCP001', 'NCD_SMR01B'],
  structuredMedicationReview: ['NCD_SMR01A'],
  asthma: ['QOF_AST007', 'QOF_AST008'],
  copd: [],
  referralPatient: [],
} as const satisfies Record<TargetAreaName, readonly TargetName[]>;

// On some occasions we want to also have a record of deprecated targets such that we can do the mapping etc. correctly
// While we won't *receive* any more new data that includes these, we will still have this data in our database so it's useful to keep this mapping
export const TargetAreasIncludingOldTargets = {
  ...TargetAreas,
  hypertension: ['QOF_HYP003', 'QOF_HYP007', ...TargetAreas.hypertension],
  cholesterol: ['QOF_CHOL001', 'QOF_CHOL002', ...TargetAreas.cholesterol],
} as const satisfies Record<TargetAreaName, readonly TargetName[]>;

/** Getting names of things out of the main dictionary */

// eslint-disable-next-line unicorn/no-array-reduce
export const AllTargetNames = Object.keys(TargetAreas).reduce(
  (accumulator, area) => {
    const targets = TargetAreas[area as keyof typeof TargetAreas];
    targets.forEach((t) => accumulator.push(t));
    return accumulator;
  },
  new Array<string>(),
) as TargetNames[];

export const getTargetAreaFromTargetIdentifier = (
  target: TargetNames,
): TargetAreaName | undefined => {
  const allAreas = Object.keys(TargetAreas) as (keyof typeof TargetAreas)[];
  return allAreas.find((area) => {
    const allTargetsInArea = TargetAreas[area] as readonly string[];
    return allTargetsInArea.includes(target);
  });
};

export type TargetsForAnArea<Area extends TargetAreaKey> =
  (typeof TargetAreas)[Area][number];

/** Manual mapping of tar get area to further info  */

export const targetAreaIsCaseFinding: Record<TargetAreaName, boolean> = {
  hypertension: false,
  diabetes: false,
  hypertensionCaseFinding: true,
  nonDiabeticHyperglycaemia: true,
  cholesterol: false,
  frailty: false,
  structuredMedicationReview: false,
  asthma: false,
  copd: false,
  referralPatient: false,
};

/** Shape of single target */

const ZEHRState = z.enum([
  'complete',
  'incomplete',
  'exception_reported',
  'not_relevant',
]);
export type EHRState = z.infer<typeof ZEHRState>;

const ZSingleTargetLog = z.object({
  state: ZEHRState,
  datetime: ZDateString,
});
export type ISingleTargetLog = z.infer<typeof ZSingleTargetLog>;

const ZSingleTarget = z.object({
  lastSynced: ZDateString,
  statusLogs: z.array(ZSingleTargetLog),
});
export type ISingleTarget = z.infer<typeof ZSingleTarget>;

/** Shape of target area status log */

export const unresolvedTargetAreaStates = [
  'active',
  'excluded',
  'did_not_engage',
  'does_not_have_equiptment',
  'is_being_managed_by_gp',
  'is_being_managed_by_a_specialist',
  'refuses_treatment',
  'refuses_diagnostics',
] as const;

type UnresolvedTargetAreaStates = (typeof unresolvedTargetAreaStates)[number];

export const unresolvedTargetAreaStateLabels: Record<
  UnresolvedTargetAreaStates,
  string
> = {
  active: 'Active',
  excluded: 'Excluded',
  did_not_engage: 'Did not engage',
  does_not_have_equiptment: 'Does not have equipment',
  is_being_managed_by_gp: 'Being managed by GP',
  is_being_managed_by_a_specialist: 'Being managed by specialist',
  refuses_treatment: 'Refuses treatment',
  refuses_diagnostics: 'Refuses diagnostics',
} as const;

// In the future we may require a target area state that captures patients who have been resolved i.e. no longer Hypertensive and off medication
export const resolvedTargetAreaStates = [] as const;

export const resolvedTargetAreaStateLabels = {
  // Define resolved state labels here, e.g.,
  // resolved: 'Resolved',
} as const;

// Combine both status types to cover all states

const combinedTargetAreaStates = [
  ...unresolvedTargetAreaStates,
  ...resolvedTargetAreaStates,
] as const;

export const unresolvedTargetAreaStatesV2 = [
  'active',
  'discharged',
  'restarted',
  'awaiting_onboarding',
  'suvera_complete',
] as const;

type UnresolvedTargetAreaStatesV2 =
  (typeof unresolvedTargetAreaStatesV2)[number];

export const UNRESOLVED_TARGET_AREA_STATES_LABELS_V2: Record<
  UnresolvedTargetAreaStatesV2,
  string
> = {
  awaiting_onboarding: 'Awaiting onboarding',
  active: 'Active',
  discharged: 'Discharged',
  suvera_complete: 'Suvera complete',
  restarted: 'Awaiting re-onboarding', // So it's not confusing for care-team
} as const;

// In the future we may require a target area state that captures patients who have been resolved i.e. no longer Hypertensive and off medication
export const resolvedTargetAreaStatesV2 = [] as const;

export const resolvedTargetAreaStateLabelsV2 = {
  // Define resolved state labels here, e.g.,
  // resolved: 'Resolved',
} as const;

const combinedTargetAreaStatesV2 = [
  ...unresolvedTargetAreaStatesV2,
  ...resolvedTargetAreaStatesV2,
] as const;

// Derive state enums from keys of the combined labels.

export const ZTargetAreaState = z.enum(combinedTargetAreaStates);

export const ZTargetAreaStateV2 = z.enum(combinedTargetAreaStatesV2);
export type ITargetAreaState = z.infer<typeof ZTargetAreaState>;

export type ITargetAreaStateV2 = z.infer<typeof ZTargetAreaStateV2>;

/** Shape of full target area record */

// Take a list of keys and map each of these to the same provides value type
const MapKeysToSameValues = <Key extends string, Val>(
  keys: Readonly<Key[]>,
  value: Val,
) => {
  const t = {} as Record<Key, Val>;
  keys.forEach((key) => {
    t[key] = value;
  });
  return t;
};

export const targetAreaStatusReasons = [
  // top-level status reasons
  'active',
  'restarted',
  'awaiting_onboarding',
  'suvera_complete',
  // discharge-related reasons
  'abusive',
  'clinically_excluded',
  'deceased',
  'did_not_engage',
  'does_not_have_equipment',
  'extended_holiday',
  'is_being_managed_by_a_specialist',
  'is_being_managed_by_gp',
  'language_barrier',
  'left_gp_practice',
  'medical',
  'another_medical_condition',
  'moved_away',
  'no_mobile_number',
  'not_interested_in_using_suvera',
  'practice_left_suvera',
  'practice_offboarded_for_target_area',
  'practice_unaware_of_suvera',
  'refuses_diagnostics',
  'refuses_treatment',
  'unhappy_with_suvera',
] as const;

export type TargetAreaStatusReason = (typeof targetAreaStatusReasons)[number];

export const TARGET_AREA_STATUS_REASON_LABELS: Record<
  TargetAreaStatusReason,
  string
> = {
  active: 'Active',
  restarted: 'Restarted',
  awaiting_onboarding: 'Awaiting onboarding',
  suvera_complete: 'Suvera complete',
  abusive: 'Patient was abusive',
  clinically_excluded: 'Clinically excluded',
  deceased: 'Deceased',
  did_not_engage: 'Did not engage',
  does_not_have_equipment: `Doesn't have correct equipment`,
  extended_holiday: 'Extended holiday',
  is_being_managed_by_a_specialist: 'Managed by specialist',
  is_being_managed_by_gp: 'Managed by GP',
  language_barrier: 'Language barrier',
  left_gp_practice: 'Left GP practice',
  medical: 'Medical reason',
  another_medical_condition: 'Has another medical condition',
  moved_away: 'Moved away',
  no_mobile_number: 'No mobile number',
  not_interested_in_using_suvera: 'Not interested in using Suvera',
  practice_left_suvera: 'Practice left Suvera',
  practice_offboarded_for_target_area: 'Practice offboarded for Target Area',
  refuses_diagnostics: 'Refuses diagnostics',
  refuses_treatment: 'Refuses treatment',
  unhappy_with_suvera: 'Unhappy with Suvera',
  practice_unaware_of_suvera: 'Practice unaware of Suvera',
} as const;

/** Types for target names */
const ZTargetAreaStatusReason = z.enum(targetAreaStatusReasons);
export type IAreaStatusReasonItem = z.infer<typeof ZAreaStatusReasonItem>;
export type IAreaStatusReason = z.infer<typeof ZTargetAreaStatusReason>;

const ZTargetAreaStatusReasonGroup = z.enum([
  'clinical',
  'patient',
  'manual',
  'practice',
]);
export type IAreaStatusReasonGroup = z.infer<
  typeof ZTargetAreaStatusReasonGroup
>;

export const ZAreaStatusReasonItem = z.object({
  reason: ZTargetAreaStatusReason.optional(),
  state: ZTargetAreaStateV2,
  note: z.string().optional(),
  createdDate: ZDateString.default(() => new Date().toISOString()),
  expiryDate: ZDateString.optional(),
  suverian: ZObjectId,
  defaultTargetAreaState: z.boolean().optional(),
  reasonGroup: ZTargetAreaStatusReasonGroup,
});

export const ZTargetAreaLog = z.object({
  state: ZTargetAreaStateV2,
  reasons: z.array(ZAreaStatusReasonItem).min(1),
  createdDate: ZDateString,
  suverian: ZObjectId,
});

export type ITargetAreaLog = z.infer<typeof ZTargetAreaLog>;

/** Shape of single target area */

const ZTargetArea = z.object({
  logs: z.array(ZTargetAreaLog),
  // TODO z.string() should be an enum of possible target names from the dictionary
  targets: z.record(z.string(), ZSingleTarget),
});

export type ITargetArea = z.infer<typeof ZTargetArea>;

// TODO these are quite generic - we have defined each area type more tightly below so
// could this be more tightly typed to have each value tied to each key properly?
export type ITargetAreaRecord = Partial<Record<TargetAreaName, ITargetArea>>;

// Hypertension

const ZHypertensionTargetsPatient = z
  .object(
    MapKeysToSameValues(
      TargetAreasIncludingOldTargets.hypertension,
      ZSingleTarget,
    ),
  )
  .partial();

const ZHypertensionTargetsEHR = z
  .object(
    MapKeysToSameValues(
      TargetAreasIncludingOldTargets.hypertension,
      ZSingleTargetLog,
    ),
  )
  .partial();

const ZHypertensionTargetAreaPatient = z.object({
  logs: z.array(ZTargetAreaLog),
  targets: ZHypertensionTargetsPatient,
});

// Diabetes

const ZDiabetesTargetsPatient = z
  .object(
    MapKeysToSameValues(TargetAreasIncludingOldTargets.diabetes, ZSingleTarget),
  )
  .partial();

const ZDiabetesTargetsEHR = z
  .object(
    MapKeysToSameValues(
      TargetAreasIncludingOldTargets.diabetes,
      ZSingleTargetLog,
    ),
  )
  .partial();

const ZDiabetesTargetAreaPatient = z.object({
  logs: z.array(ZTargetAreaLog),
  targets: ZDiabetesTargetsPatient,
});

// Hypertension Case Finding

const ZHypertensionCaseFindingTargetsPatient = z
  .object(
    MapKeysToSameValues(
      TargetAreasIncludingOldTargets.hypertensionCaseFinding,
      ZSingleTarget,
    ),
  )
  .partial();

const ZHypertensionCaseFindingTargetsEHR = z
  .object(
    MapKeysToSameValues(
      TargetAreasIncludingOldTargets.hypertensionCaseFinding,
      ZSingleTargetLog,
    ),
  )
  .partial();

const ZHypertensionCaseFindingTargetAreaPatient = z.object({
  logs: z.array(ZTargetAreaLog),
  targets: ZHypertensionCaseFindingTargetsPatient,
});

// Non Diabetic Hyperglycaemia

const ZNonDiabeticHyperglycaemiaTargetsPatient = z
  .object(
    MapKeysToSameValues(
      TargetAreasIncludingOldTargets.nonDiabeticHyperglycaemia,
      ZSingleTarget,
    ),
  )
  .partial();

const ZNonDiabeticHyperglycaemiaTargetsEHR = z
  .object(
    MapKeysToSameValues(
      TargetAreasIncludingOldTargets.nonDiabeticHyperglycaemia,
      ZSingleTargetLog,
    ),
  )
  .partial();

const ZNonDiabeticHyperglycaemiaTargetAreaPatient = z.object({
  logs: z.array(ZTargetAreaLog),
  targets: ZNonDiabeticHyperglycaemiaTargetsPatient,
});

// Cholesterol

const ZCholesterolTargetsPatient = z
  .object(
    MapKeysToSameValues(
      TargetAreasIncludingOldTargets.cholesterol,
      ZSingleTarget,
    ),
  )
  .partial();

const ZCholesterolTargetsEHR = z
  .object(
    MapKeysToSameValues(
      TargetAreasIncludingOldTargets.cholesterol,
      ZSingleTargetLog,
    ),
  )
  .partial();

const ZCholesterolTargetAreaPatient = z.object({
  logs: z.array(ZTargetAreaLog),
  targets: ZCholesterolTargetsPatient,
});

// Frailty

const ZFrailtyTargetsPatient = z
  .object(
    MapKeysToSameValues(TargetAreasIncludingOldTargets.frailty, ZSingleTarget),
  )
  .partial();

const ZFrailtyTargetsEHR = z
  .object(
    MapKeysToSameValues(
      TargetAreasIncludingOldTargets.frailty,
      ZSingleTargetLog,
    ),
  )
  .partial();

const ZFrailtyTargetAreaPatient = z.object({
  logs: z.array(ZTargetAreaLog),
  targets: ZFrailtyTargetsPatient,
});

// SMR

const ZSMRTargetsPatient = z
  .object(
    MapKeysToSameValues(
      TargetAreasIncludingOldTargets.structuredMedicationReview,
      ZSingleTarget,
    ),
  )
  .partial();

const ZSMRTargetsEHR = z
  .object(
    MapKeysToSameValues(
      TargetAreasIncludingOldTargets.structuredMedicationReview,
      ZSingleTargetLog,
    ),
  )
  .partial();

const ZSMRTargetAreaPatient = z.object({
  logs: z.array(ZTargetAreaLog),
  targets: ZSMRTargetsPatient,
});

// Asthma

const ZAsthmaTargetsPatient = z
  .object(
    MapKeysToSameValues(TargetAreasIncludingOldTargets.asthma, ZSingleTarget),
  )
  .partial();

const ZAsthmaTargetsEHR = z
  .object(
    MapKeysToSameValues(
      TargetAreasIncludingOldTargets.asthma,
      ZSingleTargetLog,
    ),
  )
  .partial();

const ZAsthmaTargetAreaPatient = z.object({
  logs: z.array(ZTargetAreaLog),
  targets: ZAsthmaTargetsPatient,
});

// COPD

const ZCOPDTargetsPatient = z
  .object(
    MapKeysToSameValues(TargetAreasIncludingOldTargets.copd, ZSingleTarget),
  )
  .partial();

const ZCOPDTargetsEHR = z
  .object(
    MapKeysToSameValues(TargetAreasIncludingOldTargets.copd, ZSingleTargetLog),
  )
  .partial();

const ZCOPDTargetAreaPatient = z.object({
  logs: z.array(ZTargetAreaLog),
  targets: ZCOPDTargetsPatient,
});

// Temporary target area add for usage in migration to remove patient-level statuses
// Should be removed in https://linear.app/suvera/issue/GREEN-279/clean-up-patients-with-referralpatient-target-area
const ZReferralTargetAreaPatient = z.object({
  logs: z.array(ZTargetAreaLog),
  targets: z.object({}),
});

/** Target area object */

export const ZTargetAreasPatient = z
  .object({
    hypertension: ZHypertensionTargetAreaPatient,
    hypertensionCaseFinding: ZHypertensionCaseFindingTargetAreaPatient,
    diabetes: ZDiabetesTargetAreaPatient,
    nonDiabeticHyperglycaemia: ZNonDiabeticHyperglycaemiaTargetAreaPatient,
    cholesterol: ZCholesterolTargetAreaPatient,
    frailty: ZFrailtyTargetAreaPatient,
    structuredMedicationReview: ZSMRTargetAreaPatient,
    asthma: ZAsthmaTargetAreaPatient,
    copd: ZCOPDTargetAreaPatient,
    referralPatient: ZReferralTargetAreaPatient,
  } satisfies Record<TargetAreaName, unknown>)
  .partial();
export type ITargetAreasPatient = z.infer<typeof ZTargetAreasPatient>;

// Separate object from above as only one object vs an array of objects for each target
export const ZTargetsEHRPatient = z
  .object({
    hypertension: ZHypertensionTargetsEHR,
    hypertensionCaseFinding: ZHypertensionCaseFindingTargetsEHR,
    diabetes: ZDiabetesTargetsEHR,
    nonDiabeticHyperglycaemia: ZNonDiabeticHyperglycaemiaTargetsEHR,
    cholesterol: ZCholesterolTargetsEHR,
    frailty: ZFrailtyTargetsEHR,
    structuredMedicationReview: ZSMRTargetsEHR,
    asthma: ZAsthmaTargetsEHR,
    copd: ZCOPDTargetsEHR,
    referralPatient: z.object({}),
  } satisfies Record<TargetAreaName, unknown>)
  .partial();

export type IHypertensionTargetAreaPatient = z.infer<
  typeof ZHypertensionTargetAreaPatient
>;
export type IAnyTargetArea = IHypertensionTargetAreaPatient &
  z.infer<typeof ZDiabetesTargetAreaPatient> &
  z.infer<typeof ZHypertensionCaseFindingTargetAreaPatient> &
  z.infer<typeof ZNonDiabeticHyperglycaemiaTargetAreaPatient> &
  z.infer<typeof ZFrailtyTargetAreaPatient> &
  z.infer<typeof ZSMRTargetAreaPatient> &
  z.infer<typeof ZAsthmaTargetAreaPatient> &
  z.infer<typeof ZCholesterolTargetAreaPatient> &
  z.infer<typeof ZCOPDTargetAreaPatient>;

export interface IAnyTargets
  extends z.infer<typeof ZHypertensionTargetsPatient>,
    z.infer<typeof ZDiabetesTargetsPatient>,
    z.infer<typeof ZHypertensionCaseFindingTargetsPatient>,
    z.infer<typeof ZNonDiabeticHyperglycaemiaTargetsPatient>,
    z.infer<typeof ZFrailtyTargetsPatient>,
    z.infer<typeof ZSMRTargetsPatient>,
    z.infer<typeof ZAsthmaTargetsPatient>,
    z.infer<typeof ZCOPDTargetsPatient> {}
